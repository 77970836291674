import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactLauriNo from "../../components/ContactLauriNo";

import LayoutNo from "../../components/LayoutNo"
import HeaderNo from '../../components/HeaderNo'
import Seo from "../../components/Seo"

const IndexPage = () => {
  return (
    <LayoutNo>
      <Seo title="Portefølje" description="Welement er en partner som hjelper entreprenører, ingeniører og utviklere med å videreutvikle byggenæringen" lang="no" />
      <HeaderNo />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Portefølje</h1>
          <p className="body-l">Byggebransjen er i endring og fabrikkbygging er i gang
en av hovedretningene for innovasjonsbølgen. Se hvordan vi har hjulpet partnerne våre til å oppnå bedre resultater.</p></div>
      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Fasadeelementer</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">


            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/paju.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="16-etasjes kontorbygg i hjertet av Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Paju 2 kontorbygg</p>
              <p className="body wel-mb-xs">16-etasjes kontorbygg i hjertet av Tartu</p>
              <p className="body-s">Tartu, Estland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/hugo.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="6-etasjes vandrerhjem i sentrum av Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hugo.Stay hostel</p>
              <p className="body wel-mb-xs">6-etasjes vandrerhjem i sentrum av Tartu</p>
              <p className="body-s">Tartu, Estland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/knivsta.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Fasadeelementer til en bygård"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Knivsta bygård</p>
              <p className="body wel-mb-xs">Fasadeelementer til en bygård</p>
              <p className="body-s">Knivsta, Sverige</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/siili.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Fasadeelementer til en bygård"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hedgehog bygård</p>
              <p className="body wel-mb-xs">Fasadeelementer til en bygård</p>
              <p className="body-s">Tartu, Estland</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Private hus</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/drobak.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Fire unike private hus nær Oslo, i Drøbak"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Drobak hus</p>
              <p className="body wel-mb-xs">Fire unike private hus nær Oslo, i Drøbak</p>
              <p className="body-s">Drobak, Norra</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/hiiekula.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Seks private hus nær Tartu. Welement tegnet, konstruerte og produserte husene"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hiieküla hus</p>
              <p className="body wel-mb-xs">Seks private hus nær Tartu. Welement tegnet, konstruerte og produserte husene</p>
              <p className="body-s">Hiieküla, Tartu</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/tartu1.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Design, engineering, produksjon, installasjon"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Privat hus i Tartu</p>
              <p className="body wel-mb-xs">Design, engineering, produksjon, installasjon</p>
              <p className="body-s">Tartu, Estland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/kloogaranna.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Design, engineering, produksjon, installasjon"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Kloogaranna hus</p>
              <p className="body wel-mb-xs">Design, engineering, produksjon, installasjon</p>
              <p className="body-s">Kloogaranna, Estland</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Rekkehus og bygårder</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/oa.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="To rekkehus og en to-etasjes bygård. Welement
                teamet tegnet, produserte og installerte modulære rekkehus og en bygård som en elementær løsning."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Utbygging av Oa Street</p>
              <p className="body wel-mb-xs">To rekkehus og en to-etasjes bygård. Welement
teamet tegnet, produserte og installerte modulære rekkehus og en bygård som en elementær løsning.</p>
              <p className="body-s">Tartu, Estland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/island.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Enetasjes rekkehus ligger i utkanten av Reikyavik. Welements team designet og produserte fabrikklagde elementer."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Rekkehus på Island</p>
              <p className="body wel-mb-xs">Enetasjes rekkehus ligger i utkanten av Reikyavik. Welements team designet og produserte fabrikklagde elementer.</p>
              <p className="body-s">Reikyavik, Island</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/helsinki.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Design, produksjon, installasjon"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Rekkehus i Helsinki</p>
              <p className="body wel-mb-xs">Design, produksjon, installasjon</p>
              <p className="body-s">Helsinki, Finland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/holmenkollen.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Fire tomannsboliger i Oslos mest prestisjefylte Holmenkollen-område"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Holmenkollen tomannsboliger</p>
              <p className="body wel-mb-xs">Fire tomannsboliger i Oslos mest prestisjefylte Holmenkollen-område</p>
              <p className="body-s">Oslo, Norra</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Samfunnsbygg</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 flex md:w-3/4 mx-auto">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/lillkyrka.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="To-etasjes omsorgsboliger i Sveriges"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Lillkyrka LSS Hooldekodu</p>
              <p className="body wel-mb-xs">To-etasjes omsorgsboliger i Sveriges</p>
              <p className="body-s">Lillkyrka, Sverige</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/nupsik.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="En-etasjes barnehage i forstedene til Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Nupsiku barnehage</p>
              <p className="body wel-mb-xs">En-etasjes barnehage i forstedene til Tartu</p>
              <p className="body-s">Tartu, Estland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/aland.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welements team designet, produserte og installerte fabrikklagde elementer"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hotell på Åland</p>
              <p className="body wel-mb-xs">Welements team designet, produserte og installerte fabrikklagde elementer</p>
              <p className="body-s">Åland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/nyby.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="To-etasjes omsorgsboliger i Sveriges"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Nyby LSS Omsorgshjem</p>
              <p className="body wel-mb-xs">To-etasjes omsorgsboliger i Sveriges</p>
              <p className="body-s">Nyby, Sverige</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/orebro.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="To-etasjes omsorgsboliger i Sveriges"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Örebro LSS Omsorgshjem</p>
              <p className="body wel-mb-xs">To-etasjes omsorgsboliger i Sveriges</p>
              <p className="body-s">Örebro, Sverige</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/almunge.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="To-etasjes omsorgsboliger i Sveriges"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Almunge LSS Omsorgshjem</p>
              <p className="body wel-mb-xs">To-etasjes omsorgsboliger i Sveriges</p>
              <p className="body-s">Almunge, Sverige</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Produktutvikling</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:w-3/4 mx-auto">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/boklok.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Ulike hjelpebygg i Sverige"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Bo Klok bygning</p>
              <p className="body wel-mb-xs">Ulike hjelpebygg i Sverige</p>
              <p className="body-s">Sverige</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/taju.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Personlig multimediaplass"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">taju.space</p>
              <p className="body wel-mb-xs">Personlig multimediaplass</p>
              <p className="body-s">For ulike kunder i Skandinavia og Baltikum</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/3by3.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Modulær enfamilieopplevelse"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">3by3 House</p>
              <p className="body wel-mb-xs">Modulær enfamilieopplevelse</p>
              <p className="body-s">For ulike kunder i Skandinavia og Baltikum</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/riffen.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welement designet, designet og produserte en restaurant i hjertet av Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Restoran RIFFEN</p>
              <p className="body wel-mb-xs">Welement designet, designet og produserte en restaurant i hjertet av Tartu</p>
              <p className="body-s">Tartu, Estland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/valgussaun.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Lysinstallasjon i samarbeid med kunstner Timo Toots"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Valgussaun</p>
              <p className="body wel-mb-xs">Lysinstallasjon i samarbeid med kunstner Timo Toots</p>
              <p className="body-s">Maajaam, Estland</p>
            </div>


          </div>
        </div>

      </div>


      <ContactLauriNo />


    </LayoutNo>
  )

};

export default IndexPage;

