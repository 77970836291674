
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"



const ContactLauriNo = () => {
  return (
    <div className="container-base">
      <div className="section-1-2-center">
        <div className="w-full bg-welement-pink p-12 rounded-lg text-center">
          <StaticImage
            src="../images/team/lauri.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Lauri"
            loading="eager"
            placeholder="blurred"
            className="w-32 rounded-full wel-mb-s"
          />
          <p className="title-s">Lauri Tuulberg</p>
          <p className="body-s wel-mb-s">CEO</p>
          <p className="body-l mb-8">Bygge med prefabrikasjon? Jo raskere du involverer en konstruksjonsspesialist, jo enklere og mer kostnadseffektiv vil hele prosessen bli.</p>
          <a href="mailto:lauri@welement.ee" className="btn btn-red mr-2 wel-mb-xs sm:mb-0">Send E-Post</a>
          <a href="tel:+37253264124" className="btn btn-black-outline">(+372) 5326 4124</a>
        </div>
      </div>
    </div >
  )
}

export default ContactLauriNo;
